import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { b64toBytes } from "../pages/pdf-sign/helper";
import docsignService from "../service/docsign.service";
import { PDFDocument } from "pdf-lib";

export const getPayload = createAsyncThunk(
  "app/doc-sign/req-payload",
  async() => {
    try {
      const data = await docsignService.requestDoc();
      const {payload, coordinates} = data;
      console.log(payload);
      const doc = payload.documents[0];
      const pdfBuffer = b64toBytes(doc.documentBase64);
      const d = await PDFDocument.load(pdfBuffer);
      // state.pages = Array(state.pageCount).fill({});
      // state.pdfBuffer = pdfBuffer;
      return {
        fileName: doc.name,
        pdfBuffer: doc.documentBase64,
        pageCount: d.getPageCount(),
        signers: payload?.recipients?.signers
      };
    }
    catch(err) {
      console.log("Cannot get document from backend!");
    }
  }
);

const tabsSlice = createSlice({
  name: "PDF",
  initialState : {
    token: undefined,
    coordinates: undefined,
    pdfBuffer: undefined,
    fileName: undefined,
    signers: undefined,
    pageCount: 0,
    pages: [],
    drawData : {
      initial: {
        width: 24,
        height: 12,
        url: undefined
      },
      sig: {
        width: 150,
        height: 20,
        url: undefined
      },
      date: {
        width: 64,
        height: 20,
        text: undefined
      },
    },
    editFinished: false,
    setupComplete: false,
    signFinished: false,
    signDate: "",
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    pdfLoad(state, action) {
      console.log("[REDUX] pdfLoad :: ", action.payload);
      const p = action.payload;
      state.pdfBuffer = p.buffer;
      state.pageCount = p.pageCount;
      state.pages = Array(p.pageCount).fill({});
    },
    setDrawData(state, action) {
      state.drawData.initial.url = action.payload.initial;
      state.drawData.sig.url = action.payload.sig;
      state.drawData.date.text = action.payload.date;
      console.log("[REDUX] setDrawData :: ", action.payload);
      state.setupComplete = true;
    },
    setTab(state, action) {
      const p = action.payload;
      const i = p.index;
      console.log("state=======>",state);
      console.log("action=======>",action);
      if (p.data.initial) {
        console.log("enter in if=======>");
        if (!state.pages[i].initial)
          state.pages[i].initial = [];
          let tempInitial = p.data.initial;
          console.log("tempInitial=======>",tempInitial);
          if(tempInitial && tempInitial.length>0){
             state.pages[i].initial = [];
            tempInitial.map((item,key)=>{
                console.log("tempInitial item=======>",item);
                console.log("tempInitial key=======>",key);
                console.log("state.pages[i]",state.pages[i]);
                state.pages[i].initial[key] = {};
              state.pages[i].initial[key].pos = item;
              state.pages[i].initial[key].drawn = false;
            });
        }
      }
      if (p.data.sig) {
        console.log("enter in if=======>");
        if (!state.pages[i].sig)
          state.pages[i].sig = [];
          let tempSig = p.data.sig;
          console.log("tempSig=======>",tempSig);
          if(tempSig && tempSig.length>0){
             state.pages[i].sig = [];
            tempSig.map((item,key)=>{
                console.log("tempSig item=======>",item);
                console.log("tempSig key=======>",key);
                console.log("state.pages[i]",state.pages[i]);
                state.pages[i].sig[key] = {};
              state.pages[i].sig[key].pos = item;
              state.pages[i].sig[key].drawn = false;
            });
        }
      }
      if (p.data.date) {
        console.log("enter in if date=======>");
        if (!state.pages[i].date)
          state.pages[i].date = [];
          let tempDate = p.data.date;
          console.log("tempDate=======>",tempDate);
          if(tempDate && tempDate.length>0){
             state.pages[i].date = [];
            tempDate.map((item,key)=>{
                console.log("tempDate item=======>",item);
                console.log("tempDate key=======>",key);
                console.log("state.pages[i]",state.pages[i]);
                state.pages[i].date[key] = {};
              state.pages[i].date[key].pos = item;
              state.pages[i].date[key].drawn = false;
            });
        }
      }
      state.editFinished = false;
      console.log("[REDUX] setTab :: ", p);
    },
    setTab1(state, action) {
      const p = action.payload;
      const i = p.index;
      if (p.data.initial) {
        if (!state.pages[i].initial)
          state.pages[i].initial = {};
        state.pages[i].initial.pos = p.data.initial;
        state.pages[i].initial.drawn = false;
      }
      if (p.data.sig) {
        if (!state.pages[i].sig)
          state.pages[i].sig = {};
        state.pages[i].sig.pos = p.data.sig;
        state.pages[i].sig.drawn = false;
      }
      if (p.data.date) {
        if (!state.pages[i].date)
          state.pages[i].date = {};
        state.pages[i].date.pos = p.data.date;
        state.pages[i].date.drawn = false;
      }
      state.editFinished = false;
      console.log("[REDUX] setTab :: ", p);
    },
    drawTab(state,action) {
      const p = action.payload;
      const i = p.index;
      const {type,sigIndex} = p;
      console.log(`[REDUX] draw ${type} tab on page(${i})`);
      if (type === "initial")
        state.pages[i].initial[sigIndex].drawn = true;
      else if (type === "signature")
        state.pages[i].sig[sigIndex].drawn = true;
      else if (type === "date")
        state.pages[i].date[sigIndex].drawn = true;
      
      let finished = true;
      for(const j in state.pages) {
        for(const k in state.pages[j].initial) {
            if (state.pages[j].initial[k]?.drawn === false){
                finished = false;
                break;
            }
        }
        for(const l in state.pages[j].sig) {
            if (state.pages[j].sig[l]?.drawn === false){
                finished = false;
                break;
            }
        }
        // if (state.pages[j].initial[sigIndex]?.drawn === false ||
        //     state.pages[j].sig[sigIndex]?.drawn === false)
        // {
        //   finished = false;
        //   break;
        // }
      }
      if(finished === true)
        state.editFinished = true;
    },
    doSign(state, action) {
      state.signFinished = true;
      state.signDate = action.payload;
    }
  },
  extraReducers : {
    [getPayload.fulfilled] : async (state, action) => {
      console.log("++++++++++++ 1 :: ", action.payload);
      const p = action.payload;
      state.pdfBuffer = p.pdfBuffer;
    },
  }
});

export const {setToken, setTab, drawTab, setDrawData, pdfLoad, doSign} = tabsSlice.actions;
export default tabsSlice.reducer;