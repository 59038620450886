import { Box, Button, Typography, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import DSInput from "../../components/DSInput";
import { useSelector } from "react-redux";
import docsignService from "../../service/docsign.service";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";

function FirstStage({onNext, contact, setContact}) {
    const mystyle = {
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      transition: "0.3s",
      borderRadius: "5px"
    }; 
  return (
    
     <>
      <h3 className="cntr">Verify your email address</h3>
      <p className="cntr"> We just need to verify your email address before proceeding to signing session.</p>
      <Typography>Email</Typography>
      <DSInput sx={{ marginBottom: "2rem"}} value={contact} onChange={({target}) => setContact(target.value)}/>
      <Button variant="contained" data={contact} onClick={() => onNext("email")}>Get Code</Button>
    </>
  );
}

function ResendStage({onNext, contact, setContact,onResend,gotoResend}) {
    const mystyle = {
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      transition: "0.3s",
      borderRadius: "5px"
    }; 
  return (
    
     <>
      <h3 className="cntr">Verify your email address</h3>
      <p className="cntr"> We just need to verify your email address before Resend verification code.</p>
      <Typography>Email</Typography>
      <DSInput sx={{ marginBottom: "2rem"}} value={contact} onChange={({target}) => setContact(target.value)}/>
      <Button variant="contained" sx={{marginBottom:"1rem"}} onClick={onResend}>Send</Button>
    </>
  );
}

function SecondStage({contact, onNext}) {
  const VerificationMethodBox = ({title, data, ...rest}) => {
    return(
    <Box border="solid 1px" p={3} {...rest} sx={{cursor: "pointer"}}>
      <Typography>{title}</Typography>
      <Typography>Use your {data} </Typography>
    </Box>
    );
  }
  return(
    <Box>
      <Typography mb={3} variant="h5" textAlign="center">Select Verification Method</Typography>
      <VerificationMethodBox mb={2} title="Email" data={contact} onClick={() => onNext("email")}/>
    </Box>
  )
}

function VerificationUI({code, setCode, onVerify,gotoResend}) {
  return(
    <Box display="flex" flexDirection="column">
      <Typography mb={3} variant="h5" textAlign="center">
        Get code from your email inbox
      </Typography>
      <Typography mb={3} textAlign="center">
        Enter code received in your email
      </Typography>
      <DSInput fullWidth sx={{marginBottom:"2rem"}} value={code} onChange={({target})=> setCode(target.value)}/>
      <Button variant="contained" sx={{marginBottom:"1rem"}} onClick={onVerify}>Verify</Button>
      <Button  onClick={gotoResend}>Resend Code</Button>
    </Box>
  )
}

function FinalStage({onNext}) {
    const mystyle = {
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      transition: "0.3s",
      borderRadius: "5px"
    }; 
  return (
    
     <>
      <p className="cntr">You already signed the contract -if there is some error please comtact the document sender at provide email address</p>
    </>
  );
}

function InformBox({msg, ...rest}) {
  if (msg)
    return(
      <Typography {...rest} color="#c50b0b" textAlign="center">
        {msg}
      </Typography>
    );
  else return(<></>)
}

export default function AuthPage() {
  const { code } = queryString.parse(window.location.search);
//   if(code){
//     const [stage, setStage] = useState(2);
//     console.log("mayank code if==>",code);
//   }else{
//     const [stage, setStage] = useState(0);
//   }
  const [stage, setStage] = useState(0);
  const [contact, setContact] = useState();
  const [phone, setPhone] = useState();
  const [verifType, setVerifType] = useState();
  const {token} = useSelector(state=>state.tabs);
  const [verifCode, setVerifiCode] = useState();
  const navigate = useNavigate();
  const [isInitialRender, setIsInitialRender] = useState(true); // Track initial rendering
  const [infoMsg, setInfoMsg] = useState();
  
  useEffect(() => {
    setInfoMsg(undefined);
    if(code=="code" && isInitialRender===true){
        setStage(2);
        console.log("mayank code if==>",code);
    }
    if(code=="signed" && isInitialRender===true){
        setStage(4);
        console.log("mayank signed if==>",code);
    }
  }, [stage,isInitialRender]);
  function gotoSecondStage() {
    if (!contact) {
      setInfoMsg("Plese enter contact information in the above input boxes.")
      return;
    }
    setStage(1);
  }
  function gotoResend() {
    console.log("mayank in resend function");
    setStage(3);
    setIsInitialRender(false);
  }

  async function gotoVerify(type) {
      if (!contact) {
      setInfoMsg("Plese enter contact information in the above input boxes.")
      setStage(0);
      return;
    }
    try {
      const resp = await docsignService.auth(token, {type: type, addr: type==="email"?contact:phone});
      console.log("+++++++++++ :: ", token, resp.message);
      setInfoMsg(resp.message);
      setStage(2);
      setVerifType(type);
    } catch(e) {
      setInfoMsg(e.message);
    }
  }
  function gotoLogin() {
    navigate("/login");
    setStage(0);
  }
  
  async function onVerify() {
    setInfoMsg(undefined);
    docsignService.verify(token, verifCode)
    .then(resp => {
      console.log("+++++++++++ :: ", resp);
      navigate(`/app/doc-sign/?token=${token}`);
    })
    .catch(e => {
      console.log("********* error :: ", e);
      setInfoMsg(e.message);
    });
  }
  async function onResend() {
    try {
      const resp = await docsignService.auth(token, {type: verifType, addr: verifType==="email"?contact:phone});
      setStage(2);
      setInfoMsg(resp.message);
    } catch(e) {
      setInfoMsg(e.message);
    }
  }

  return (
    <Box
      pt="20%"
      px="10%"
      position="relative"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      className = "firstPage"
    >
       <Box display="flex" justifyContent="center" mb="5%">
        <Avatar variant={"square"} alt="The image" src={'images/blinx concept_v2.jpg'} style={{
            width: 250,
            height: 200,
          }} />
      </Box>
      <Box display="flex" justifyContent="center" mb="5%">
        <Typography variant="h5">SmartContracts</Typography>
      </Box>
      {
        stage===0 ? 
          (<FirstStage 
            onNext={gotoVerify} 
            contact={contact} 
            setContact={setContact}
            
            // phone={phone}
            // setPhone={setPhone}
          />)
        : stage ===1 ? 
          (<SecondStage contact={contact}  onNext={gotoVerify}/>)
        : stage === 2 ? 
          (<VerificationUI code={verifCode} setCode={setVerifiCode} onVerify={onVerify} gotoResend={gotoResend}/>)
        : stage === 3 ? 
          (<ResendStage contact={contact} setContact={setContact} onVerify={onVerify} onResend = {onResend} gotoResend={gotoResend}/>)
        : stage === 4 ? 
          (<FinalStage onNext={gotoLogin}/>)
        : (<></>)
      }
      <InformBox mt={5} msg={infoMsg}/>
    </Box>
  );
}
