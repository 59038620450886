import { Box, Tab, Tabs, Typography, Avatar } from "@mui/material";
import {withStyles} from "@mui/styles";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
export default function Thanku() {
 const navigate = useNavigate();
  const handleClick = (event) => {
    navigate("/login");
  };
  const closeTab = () => {
    window.opener = null;
    window.open("https://esignapp.brokerlinx.com/login", "_self");
    window.close();
    console.log("---------------------close tab");
  };
  return (
    <Box width="90%"  paddingTop="1%">
        <Box display="flex" justifyContent="right" mb="1%">
          <span onClick={closeTab}><CloseIcon /></span>
        </Box>
        <Box display="flex" justifyContent="center" mb="5%">
            <img src="/images/blinx concept_v2.jpg" alt="React Image" style={{
            width: 250,
            height: 200,
          }} />
        </Box>
        <h2 className="thnkh2">Congratulations! </h2>
        <h3 className="thnkh3">You have finished signing the document. You will receive an email of copy of document once all parties have completed the signing process.</h3>
    </Box>
  );
}

