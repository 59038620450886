import { Routes, Route, Navigate } from "react-router-dom";
import Main from "./pages/main";
import Login from "./pages/auth/login";
import PrivateRoute, { PublicRoute } from "./routes/route";
import PdfSign from "./pages/pdf-sign/PdfSign";
import Test from "./pages/test";
import { Box } from "@mui/material";
import AuthPage from "./pages/authenticate";
import { useState, useEffect } from "react";
import { browserName, CustomView } from 'react-device-detect';
import Thanku from "./pages/thankyou";
import './global.css';
function App() {
  const [proxyAvailable, setProxyAvailable] = useState(false);
  useEffect(() => {
    console.log("++++++++++ sending reqdoc +++++++++", browserName);
     async function checkProxy() {
      try {
        const response = await fetch('https://esign.brokerlinx.com/');
        if (response.ok) {
          setProxyAvailable(true);
          console.log("==========>inside ok proxy");
        } else {
          setProxyAvailable(false);
          console.log("==========>not inside ok proxy");
        }
      } catch (error) {
        console.error(error);
        setProxyAvailable(false);
      }
    }
    checkProxy();
    alert(`Detected browser : ${browserName}`);
  }, []);
  return (<Box display="flex" flexDirection="column" height="100%">
    <Routes>
      <Route
        path="/app"
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }
      >
        <Route path="thanku" element={<Thanku/>}/>    
        <Route path="doc-sign" element={<PdfSign/>}/>
        <Route path="test" element={<Test/>}/>
      </Route>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <AuthPage />
          </PublicRoute>
        }
      />

      <Route exact path="/" element={<Navigate to="/app" />} />
    </Routes>
    </Box>
  );
}

export default App;
