export default function getAbbrName(name) {
  if (!name) return "";
  console.log("getAbbrName before with space",name);    
  name = name.replace(/\s\s+/g, ' ');
  console.log("getAbbrName after with space",name);   
  const nParts = name.split(" ");
  let abrName = "";
  nParts.map((p) => {
    abrName += p[0];
  });
  return abrName;
}
