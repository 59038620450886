import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { forwardRef, useEffect, useRef, useState } from "react";
import getAbbrName from "./helpers";
import SignaturePad from "react-signature-canvas";
import FontPicker from "font-picker-react";
import DSButton from "../../../../components/DSButton";
import DSTypography from "../../../../components/DSTypography";
import DSBox from "../../../../components/DSBox";
import DSInput from "../../../../components/DSInput";
import { Link } from "react-router-dom";

function NameBox({ label, value, setValue, ...rest }) {
  return (
    <Grid container wrap="nowrap" flexDirection="column" {...rest}>
      <Typography fontSize="12px">
        {label}
        <Typography component="span" color="#bd0808">
          &nbsp;*
        </Typography>
      </Typography>
      <DSInput
        required
        id="outlined-required"
        value={value}
        onChange={({ target }) => setValue(target.value)}
        sx={{
          border: "solid 2px grey"
        }}
        InputProps = {{
          style : {
            fontSize: "2vh"
          }
        }}
      />
    </Grid>
  );
}

export default function SignerNamePanel({
  name,
  setName,
  abrName,
  setAbrName,
  ...rest
}) {
  return (
    <Grid container {...rest} justifyContent="space-between">
      <NameBox label="FULL NAME" value={name} setValue={setName} xs={6}/>
      <NameBox label="INITIALS" value={abrName} setValue={setAbrName} xs={4}/>
    </Grid>
  );
}

const DSToggleButton = styled(ToggleButton)(({theme}) => {
  const {button, white, primary } = theme?.palette;
  return {
    backgroundColor: button.main,
    color: white.main,
    borderRadius: "0",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: primary.main,
      color: white.main,
    },
    "&:hover" : {
      backgroundColor: button.main,
      color: "orange",
    }
  }
});

export function ChooseStyle({ onStyleChange, ...rest }) {
  const [type, setType] = useState(1);
  const [editType, setEdityType] = useState("text");

  useEffect(() => {
    if (!onStyleChange)
      return;
    
    switch(editType) {
      case "text":
      case "upload":
        onStyleChange(1);
        break;
      case "pen":
        onStyleChange(0);
        break;
    }
    // onStyleChange(type);
  }, [editType]);

  function handleChange(event, newType) {
    setEdityType(newType);
  }

  return (
    <Grid container {...rest}>
      <ToggleButtonGroup 
        exclusive
        value = {editType}
        onChange = {handleChange}
        fullWidth
        sx={{maxHeight:"5vh"}}
      >
        <DSToggleButton 
          value="text" 
        >
            Text
        </DSToggleButton>
        <DSToggleButton
          value="pen"
        >
          Pen
        </DSToggleButton>
        <DSToggleButton
          value="upload"
        >
          upload
        </DSToggleButton>
      </ToggleButtonGroup>
      {/* <FormControl {...rest} style={{ flex: 1 }}>
        <InputLabel id="draw-style-label">CHOOSE STYLE</InputLabel>
        <Select
          labelId="draw-style-label"
          id="draw-style"
          value={type}
          label="CHOOSE STYLE"
          onChange={({ target }) => setType(target.value)}
          inputProps={{
            sx: {
              padding: "0.5rem",
            },
          }}
        >
          <MenuItem value={0}>PEN</MenuItem>
          <MenuItem value={1}>TEXT</MenuItem>
        </Select>
      </FormControl> */}
      {/* <DSButton style={{ flex: 1 }}>{type === 0 ? "DRAW" : "TEXT"}</DSButton> */}
      {/* <DSButton style={{ flex: 1 }}>UPLOAD</DSButton> */}
    </Grid>
  );
}

const FingerDrawPanel = forwardRef(({ type, title, ...rest }, ref) => {
  useEffect(() => {
    if (type == 1) {
      ref.current.off();
    } else {
      ref.current.on();
    }
  }, [type, ref]);

  return (
    <DSBox border="solid 2px" borderColor="border" height= "20vh">
      <Typography backgroundColor="yellow">{title}</Typography>
      <SignaturePad
        dotSize={2}
        minWidth={1}
        maxWidth={2}
        canvasProps={{
          style: {
            width: "100%",
            height: "16vh",
          },
        }}
        ref={ref}
      />
    </DSBox>
  );
});

const TextStyledPanel = forwardRef(
  ({ title, text, activeFont, ...rest }, ref) => {
    return (
      <Box {...rest}>
        <Typography backgroundColor="yellow">{title}</Typography>
        <TextField
          fullWidth
          value={text}
          {...rest}
          InputProps={{
            sx: {
              fontFamily: activeFont,
              fontSize: "xxx-large",
            },
          }}
        />
      </Box>
    );
  }
);

export const fontList = [
  "Mr Dafoe",
  "Pacifico",
  "Permanent Marker",
  "Sriracha",
  "Yellowtail",
  "Architects Daughter",
  "Berkshire Swash",
  "Caveat Brush",
  "Damion",
  "Gochi Hand",
];

function ChangeFonts({ name, onChangeFont }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    onChangeFont(fontList[0]);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeFont = (font) => {
    setAnchorEl(null);
    if (onChangeFont) onChangeFont(font);
  };

  return (
    <Box>
      <DSButton
        id="basic-button"
        variant="gradient"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "mediumblue",
          textTransform: "none",
        }}
      >
        Change STYLE
      </DSButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fontList.map((f, i) => (
          <MenuItem
            key={`font-change-${i}`}
            onClick={() => handleChangeFont(f)}
          >
            <Typography fontFamily={f}>{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
export const DrawPanel = forwardRef(({type, name, abrName, agree, setAgree, ...rest}, ref) => {
  const { signRef, initialRef } = ref;
  const [activeFont, setActiveFont] = useState();
  const [openDisclosure, setOpenDisclosure] = useState(false);

  function drawBySystemFont() {
    if (type !== 1) return;
    clearSign();
    console.log("drawBySystemFont :: ", activeFont);
    console.log("signRef----->",signRef?.current.getCanvas()?.clientWidth);
    let clientWidth = signRef?.current.getCanvas()?.clientWidth;
    if(clientWidth>100){
        clientWidth = clientWidth-15;
    }
    let ctx = signRef?.current?.getCanvas()?.getContext("2d");
    ctx.font = `48px ${activeFont}`;
    ctx.fillText(name, 10, 50,clientWidth);

    ctx = initialRef?.current?.getCanvas()?.getContext("2d");
    ctx.font = `48px ${activeFont}`;
    ctx.fillText(abrName, 10, 50,clientWidth);
  }

  useEffect(() => {
    clearSign();
    drawBySystemFont();
  }, [type, activeFont, name, abrName]);

  function clearSign() {
    // setSignText("");
    signRef?.current?.clear();
    initialRef?.current?.clear();
  }
    //  history.pushState(null, null, location.href);
    // window.onpopstate = function (event) {
    //   history.go(1);
    // };
  return (
    <Grid container wrap="nowrap" flexDirection="column" px={1} {...rest}>
      <Grid
        item
        mt={2}
        container
        justifyContent="space-between"
        alignItems="center"
        maxHeight="5vh"
      >
        <DSTypography fontSize={{sm:"1.5rem", xs:"1rem"}} color="border">
          Preview
        </DSTypography>
        <Button
          variant="text"
          color="primary"
          onClick={type === 0 ? clearSign : () => {}}
        >
          Clear
        </Button>
      </Grid>
      <Box maxHeight="50vh" overflow="hidden">
        <FingerDrawPanel title="Signature" type={type} ref={signRef} />
        <FingerDrawPanel title="Initial" type={type} ref={initialRef} />
        {type === 1 && (
          <ChangeFonts name={name} onChangeFont={setActiveFont} />
        )}
      </Box>
      <Typography mt={1} fontSize={{ xs: "10px", sm: "16px" }} maxHeigh="20vh" overflow="hidden">
        By selecting Adopt and Sign, I agree that the signature and initals will
        be my electronic representation of my Signature and Initials for all
        purposes within these documents. When I, my agent, or my representative
        use them on all documents both on binding and non binding contacts, such
        signatures and initals will act just the same as a Pen to Paper
        signature and initial.
      </Typography>
      <Grid container flexDirection="column" alignItems="center" >
        <Typography 
          component={Link} 
          to="#" 
          fontSize={{ xs: "10px", sm: "16px" }}
          onClick = {() => setOpenDisclosure(true)}
        >
          Electronic Record and Signature Disclosure
        </Typography>
        <FormControlLabel
          control = {
            <Checkbox 
              checked={agree} 
              onChange={({target}) => setAgree(target.checked)}
              sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: {xs:16, sm:28} } }}
            />
          }
          label="I agree to use electronic records and signatures"
          sx={{
            "& .MuiFormControlLabel-label" : {
              marginLeft: "4px",
              fontSize: { xs: "10px", sm: "16px"}
            }
          }}
        />
       </Grid>
       <Modal 
        open={openDisclosure} 
        onClose={() => setOpenDisclosure(false)}
        fullWidth={false}
        sx={{
          display:"flex",
          justifyContent: "center",
          alignItems: "center",
          background: 'black',
         overflow: 'auto',
         height: '100vh'
        }}
      >
        <Box sx={{
          border: '2px solid #000',
          background: 'black',
          padding: 2,
          overflow: 'auto',
          height: '100vh'
        }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton color="primary" onClick={() => setOpenDisclosure(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
          <Typography textAlign="center" fontSize={{ xs: "12px", sm: "16px" }}>
            Electronic Record and Signature Disclosure
          </Typography>
             <Typography fontSize={{ xs: "11px", sm: "14px" }}>
      <p>ELECTRONIC RECORD AND SIGNATURE DISCLOSURE<br /><br /></p>
      <p>From time to time, BrokerLinx LLC (we, us, or Company) may be required by law to provide you with certain written notices or disclosures. Described below are the terms and conditions for providing such notices and disclosures electronically through the BrokerLinx system. Please read the information below carefully and thoroughly. If you can access this information electronically to your satisfaction and agree to this Electronic Record and Signature Disclosure (ERSD), please confirm your agreement by selecting the checkbox next to 'I agree to use electronic records and signatures' before clicking 'CONTINUE' within the BrokerLinx system.<br /><br /></p>
      <p>Getting paper copies<br /><br /></p>
      <p>At any time, you may request from us a paper copy of any record provided or made available electronically to you by us. You will have the ability to download and print documents we send to you through the BrokerLinx system during and immediately after the signing session. If you elect to create a BrokerLinx account, you may access the documents for a limited period of time (usually 30 days) after such documents are first sent to you. After that time, if you wish for us to send you paper copies of any such documents from our office, you will be charged a $[[CopyCostPerPage]] per-page fee. You may request the delivery of such paper copies from us by following the procedure described below.<br /><br /></p>
      <p>Withdrawing your consent<br /><br /></p>
      <p>If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and tell us that you want to receive required notices and disclosures only in paper format. To inform us of your decision to receive future notices and disclosures in paper format and withdraw your consent to receive notices and disclosures electronically, please follow the procedure described below. <br /><br /></p>
      <p>Consequences of changing your mind<br /><br /></p>
      <p>If you elect to receive required notices and disclosures only in paper format, it will slow down the speed at which we can complete certain steps in transactions with you and delivering services to you. This is because we will need to send the required notices or disclosures to you in paper format first and then wait until we receive back from you an acknowledgment of your receipt of such paper notices or disclosures. Furthermore, you will no longer be able to use the BrokerLinx system to receive required notices and consents electronically from us or to sign electronically documents from us. <br /><br /></p>
      <p>All notices and disclosures will be sent to you electronically<br /><br /></p>
      <p>Unless you tell us otherwise in accordance with the procedures described herein, we will provide electronically to you through the DocuSign system all required notices, disclosures, authorizations, acknowledgments, and other documents that are required to be provided or made available to you during our relationship with you. To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required notices and disclosures to you by the same method and to the same address that you have given us. Thus, you can receive all the disclosures and notices electronically or in paper format through the paper mail delivery system. If you do not agree with this process, please let us know as described below. Please also see the paragraph immediately above that describes the consequences of your electing not to receive delivery of the notices and disclosures electronically from us.<br /><br /></p>
      <p>How to contact BrokerLLinx LLC: <br /><br /></p>
      <p>You may contact us to let us know of your changes regarding how we may contact you electronically, to request paper copies of certain information from us, and to withdraw your prior consent to receive notices and disclosures electronically as follows:<br /><br /></p>
      <p>[[WithdrawOptions]]  <br /><br /></p>
      <p>To advise BrokerLinx LLC of your new email address:<br /><br /></p>
      <p>To let us know of a change in your email address where we should send notices and disclosures electronically to you, you must send an email message to us at [[ChangeEmail]] and in the body of such request, you must state your previous email address and your new email address. <br /><br /></p>
      <p>[[ChangeEmailOther]]<br /><br /></p>
      <p>If you created a BrokerLinx account, you may update it with your new email address through your account preferences.</p>
      <br /><br />
      <p>To request paper copies from BrokerLinx LLC:</p>
      <br /><br />
      <p>To request delivery from us of paper copies of the notices and disclosures previously provided by us to you electronically, you must send us an email to [[CopyRequestEmail]] and in the body of such request, you must state your email address, full name, mailing address, and telephone number.</p>
      <br /><br />
      <p>[[CopyFeeCollectionMethod]]</p>
      <br /><br />
      <p>To withdraw your consent with BrokerLinx LLC:</p>
      <br /><br />
      <p>To inform us that you no longer wish to receive future notices and disclosures in electronic format, you may:</p>
      <ul>
        <li>- Decline to sign a document from within your signing session, and on the subsequent page, select the checkbox indicating you wish to withdraw your consent.</li>
        <li>- Send us an email to [[WithdrawEmail]] and in the body of such request, you must state your email, full name, mailing address, and telephone number. [[WithdrawOther]].</li>
      </ul>
      <br /><br />
      <p>[[WithdrawConsequences]].</p>
      <br /><br />
      <p>Required hardware and software:</p>
      <br /><br />
      <p>The minimum system requirements for using the BrokerLinx system may change over time. The current system requirements are found here: Signer Guide Signing System Requirements.</p>
      <br /><br />
      <p>Acknowledging your access and consent to receive and sign documents electronically:</p>
      <br /><br />
      <p>To confirm to us that you can access this information electronically, which will be similar to other electronic notices and disclosures that we will provide to you, please confirm that you have read this ERSD and:</p>
      <ul>
        <li>- You can print on paper or electronically save this ERSD for your future reference and access.</li>
        <li>- You can email this ERSD to an email address where you will be able to print it or save it for your future reference and access.</li>
      </ul>
      <br /><br />
      <p>Furthermore, if you consent to receiving notices and disclosures exclusively in electronic format as described herein, then select the checkbox next to 'I agree to use electronic records and signatures' before clicking 'CONTINUE' within the BrokerLinx system.</p>
      <br /><br />
      <p>By selecting the checkbox next to 'I agree to use electronic records and signatures', you confirm that:</p>
      <ul>
        <li>- You can access and read this Electronic Record and Signature Disclosure.</li>
        <li>- You can print on paper this Electronic Record and Signature Disclosure or save/send this Electronic Record and Disclosure to a location where you can print it for future reference and access.</li>
        <li>- Until or unless you notify BrokerLinx LLC as described above, you consent to receive exclusively through electronic means all notices, disclosures, authorizations, acknowledgments, and other documents that are required to be provided or made available to you by BrokerLinx LLC during your relationship with BrokerLinx LLC.</li>
      </ul>
    </Typography>
        </Box>
      </Modal>
    </Grid>
  );
});

export function FinishSettings({ onSign, onCancel, agree, ...rest }) {
  return (
    <Grid container {...rest}>
      <DSButton
        onClick={onSign}
        disabled={agree}
        sx={{
          flex: 1,
          textTransform: "none",
        }}
      >
        ADOPT and SIGN
      </DSButton>
      {agree?
      <DSButton
        variant="contained"
        sx={{
          flex: 1,
          color: "#c84c09",
          background: "white",
          textTransform: "none",
        }}
        onClick={onCancel}
      >
        Cancel
      </DSButton>
        :<button className="btn" style={{
          flex: 1,
          color: "#c84c09",
          background: "black",
          textTransform: "none",
          fontWeight:"bold",
          cursor:"pointer"
        }}
        onClick={onCancel}>Cancel</button>
        }
    </Grid>
  );
}
